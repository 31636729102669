body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.asrt-page-length{
  align-items: center !important;
}
.asrt-page-length .input-group-addon{
  background: none !important;
}
.react-date-picker__wrapper{
  border: 0 !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

header, main, footer {
  padding-left: 300px;
}
/*.modal.show .modal-dialog {
    margin: 90px auto;
}*/

@media only screen and (max-width : 992px) {
  header, main, footer {
    padding-left: 0;
  }
}
.navbar-brand img {
    width: 120px;
}
.trxid a,
.useraddress a {
    display: block;
    width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
}
